import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import couponService from './couponService'
import { toast } from 'react-toastify'

const initialState = {
  coupons: [],  
  coupon_orders: [],
  coupon: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
}

// Get all coupons
export const getCoupons = createAsyncThunk('coupons/getAll', async (_, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token
        return await couponService.getCoupons(token)
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

// Fetch shipping labels for selected orders
export const exportCoupons = createAsyncThunk('coupons/export', async (ids, thunkAPI) => {
    try {
        // const token = thunkAPI.getState().auth.user.token;
        return await couponService.exportAllCoupons();
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

// Get coupon by ID
export const getCouponById = createAsyncThunk('coupons/getById', async (id, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token
        return await couponService.getCouponById(token, id)
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString()
        return thunkAPI.rejectWithValue(message)
    }
});

// Create a coupon
export const createCoupon = createAsyncThunk('coupons/create', async (coupon, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token
        return await couponService.createCoupon(token, coupon)
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString()
        return thunkAPI.rejectWithValue(message)
    }
});


// Update a coupon
export const updateCoupon = createAsyncThunk('coupons/update', async (coupon, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token
        return await couponService.updateCoupon(token, coupon)
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString()
        return thunkAPI.rejectWithValue(message)
    }
});

// Delete a coupon
export const deleteCoupon = createAsyncThunk('coupons/delete', async (id, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token
        return await couponService.deleteCoupon(token, id)
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString()
        return thunkAPI.rejectWithValue(message)
    }
});

const downloadExcel = (data, filename) => {
    // Create a Blob from the Excel data (received as a buffer from the backend)
    const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    // Create a link element
    const link = document.createElement('a');
    
    // Create a URL for the Blob and set it as the href attribute of the link
    link.href = window.URL.createObjectURL(blob);
    
    // Set the download attribute to the filename you want
    link.download = filename;

    // Append the link to the document body
    document.body.appendChild(link);

    // Programmatically click the link to trigger the download
    link.click();

    // Clean up by removing the link from the DOM
    document.body.removeChild(link);
};

export const couponSlice = createSlice({
    name: 'coupon',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false
            state.isSuccess = false
            state.isError = false
            state.message = ''
            state.coupon = null
        },
        removeDiscount: (state) => {
            state.coupon = null
        }
    },
    extraReducers: (builder) => {
      builder
        .addCase(getCoupons.pending, (state) => {
            state.isLoading = true
        })
        .addCase(getCoupons.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.coupons = action.payload
        })
        .addCase(getCoupons.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
        })
        .addCase(getCouponById.pending, (state) => {
            state.isLoading = true
        })
        .addCase(getCouponById.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.coupon = action.payload.coupon
            state.coupon_orders = action.payload.orders
        })
        .addCase(getCouponById.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
        })
        .addCase(createCoupon.pending, (state) => {
            state.isLoading = true
        })
        .addCase(createCoupon.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.coupon = action.payload
        })
        .addCase(createCoupon.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
        })
        .addCase(updateCoupon.pending, (state) => {
            state.isLoading = true
        })
        .addCase(updateCoupon.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.coupon = action.payload
        })
        .addCase(updateCoupon.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
        })
        .addCase(deleteCoupon.pending, (state) => {
            state.isLoading = true
        })
        .addCase(deleteCoupon.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.coupons = state.coupons.filter((coupon) => coupon._id !== action.meta.arg)
            
        })
        .addCase(deleteCoupon.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload.message
        })
        .addCase(exportCoupons.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(exportCoupons.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            downloadExcel(action.payload, 'orders.xlsx');
        })
        .addCase(exportCoupons.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
            toast.error(action.payload);
        })
    },
  })
  
  export const { reset, removeDiscount } = couponSlice.actions
  export default couponSlice.reducer