import axios from 'axios'

const API_URL = '/coupons/'

// Get all coupons
const getCoupons = async (token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    
    const response = await axios.get(API_URL, config)
    return response.data
}

// Export all coupons
const exportAllCoupons = async (token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
        responseType: 'blob',
    };
    const response = await axios.get(API_URL + 'export', config)
    return response.data
}

// Get coupon by ID
const getCouponById = async (token, id) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    const response = await axios.get(API_URL + id, config)
    return response.data
}

// Create a coupon
const createCoupon = async (token, coupon) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    const response = await axios.post(API_URL, coupon, config)
    return response.data
}

// Update a coupon
const updateCoupon = async (token, coupon) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    const response = await axios.put(API_URL + coupon._id, coupon, config)
    return response.data
}

// delete a coupon
const deleteCoupon = async (token, id) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    const response = await axios.delete(API_URL + id, config)
    return response.data
}

const couponService = {
    getCoupons,
    getCouponById,
    createCoupon,
    updateCoupon,
    deleteCoupon,
    exportAllCoupons
}
  
export default couponService